import React, { useState, useEffect, useContext, useReducer } from "react";
import './App.css';
import QRCode from "qrcode.react";

function App() {
  const [inputs, setInputs] = useState({publicUrl:""});
  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };
  const downloadPNG = (e) => {
    console.log(e);
    let c = document.getElementById("qrcode").firstChild;

    var a = document.createElement("a");
    a.href = c.toDataURL("image/png");
    a.download = "qr-code.png";
    a.click();
  };
  
  return (
    <div className="App">
      <div id="qrcode" className="qrcode">
      <QRCode
                            size={1024}
                            value={inputs.publicUrl}
                          />
                          </div>
                          <div className="download">
                          <button
                          className="btn btn-link py-0"
                          onClick={downloadPNG}
                        >
                          Download PNG
                        </button>
                          </div>
        <div class="public-url">
          <label>URL</label>
        <input
                          type="text"
                          id="publicUrl"
                          name="publicUrl"
                          value={inputs.publicUrl}
                          onChange={handleChange}
                          className="form-control"
                        />
        </div>

    </div>
  );
}

export default App;
